import GenerateAgreement from "../../../jsx/components/Dashboard/LoanApplications/generateagreement";

const GenerateAgreementWrapper = () => {
  return <GenerateAgreement />;
};

export const allowedRoles = [
  _enum.ROUTE_PERSONAS.admin,
  _enum.ROUTE_PERSONAS.sales_lead,
  _enum.ROUTE_PERSONAS.senior_underwriter,
  _enum.ROUTE_PERSONAS.underwriter,
];
export default constore(GenerateAgreementWrapper);
