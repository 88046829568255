import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const AddressDetails = (props) => {
  var isReadonly = props.urlparams.params.readonly === "true" ? true : false;
  const frompath = props?.urlparams?.params?.path;
  const applId = props.urlparams.params.id;

  const [communicationFields, setCommunicationFields] = useState([
    {
      name: "residenceStatus",
      label: "Residence Status",
      type: "select",
      options: _enum.RESIDENCE_TYPES,
    },
    {
      name: "pincode",
      label: "PIN code",
      type: "number",
      placeholder: "Please enter",
      maxlength: 6,
    },
    {
      name: "state",
      label: "State",
      type: "select",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "city",
      label: "City",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "landmark",
      label: "Landmark",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "spacer",
      type: "spacer",
    },
    {
      name: "address1",
      label: "Address 1",
      type: "textarea",
      placeholder: "Please enter",
      // customcolspan: 8,
    },
    {
      name: "address2",
      label: "Address 2",
      type: "textarea",
      placeholder: "Please enter",
      // customcolspan: 8,
    },
    {
      name: "address3",
      label: "Address 3",
      type: "textarea",
      placeholder: "Please enter",
      // customcolspan: 8,
    },
    {
      name: "docType",
      label: "Document Type",
      type: "select",
      options: _enum.DOC_TYPES_NEW,
    },
    {
      name: "fileId",
      label: "Document Upload",
      fileLabel: "Upload document",
      foldername: "customerAddressDocs",
      applicationId: applId,
      type: "file",
      accept: ".png, .jpg, .jpeg",
      placeholder: "Please enter",
    },
  ]);
  const [permanentFields, setPermanentFields] = useState([
    {
      name: "residenceStatusPM",
      label: "Residence Status",
      type: "select",
      options: _enum.RESIDENCE_TYPES,
    },
    {
      name: "pincodePM",
      label: "PIN code",
      type: "number",
      placeholder: "Please enter",
      maxlength: 6,
    },
    {
      name: "statePM",
      label: "State",
      type: "select",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "cityPM",
      label: "City",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "landmarkPM",
      label: "Landmark",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "spacerPM",
      type: "spacer",
    },
    {
      name: "address1PM",
      label: "Address 1",
      type: "textarea",
      placeholder: "Please enter",
      // customcolspan: 8,
    },
    {
      name: "address2PM",
      label: "Address 2",
      type: "textarea",
      placeholder: "Please enter",
      // customcolspan: 8,
    },
    {
      name: "address3PM",
      label: "Address 3",
      type: "textarea",
      placeholder: "Please enter",
      // customcolspan: 8,
    },
    {
      name: "docTypePM",
      label: "Document Type",
      type: "select",
      options: _enum.DOC_TYPES_NEW,
    },
    {
      name: "fileIdPM",
      label: "Document Upload",
      fileLabel: "Upload document",
      foldername: "customerAddressDocs",
      applicationId: applId,
      type: "file",
      accept: ".png, .jpg, .jpeg",
      placeholder: "Please enter",
    },
  ]);

  const [fields, setFields] = useState(communicationFields);
  const [addressEditData, setAddressEditData] = useState({});
  const [customerApplicationInfo, setCustomerApplicationInfo] = useState({});
  const hasEditData =
    customerApplicationInfo?.ApplicationAddressInfo?.length > 0 ? true : false;

  const handleAddressForm = (e, data) => {
    let cmData = {
      addressType: "COMMUNICATION",
      residenceStatus: data?.residenceStatus,
      pincode: data?.pincode,
      state: data?.state,
      city: data?.city,
      landmark: data?.landmark,
      address1: data?.address1,
      address2: data?.address2,
      address3: data?.address3,
      docType: data?.docType,
      fileId: data?.fileId,
    };
    let pmData = {
      addressType: "PERMANENT",
      residenceStatus: data?.residenceStatusPM,
      pincode: data?.pincodePM,
      state: data?.statePM,
      city: data?.cityPM,
      landmark: data?.landmarkPM,
      address1: data?.address1PM,
      address2: data?.address2PM,
      address3: data?.address3PM,
      docType: data?.docTypePM,
      fileId: data?.fileIdPM,
    };
    console.log({ cmData, pmData });
    props.apis
      .apiAddCustomerPostDocsAddressInfo(cmData, {
        applicationId: applId,
      })
      .then(() => {
        props.apis
          .apiAddCustomerPostDocsAddressInfo(pmData, {
            applicationId: applId,
          })
          .then(() => {
            props.changeTab(e, "incomedetails");
          });
      });
  };

  const getBackLink = () => {
    let link;
    if (frompath === "services") {
      link = `/app/services`;
    }
    return link;
  };

  const updateFields = (fields, data) => {
    let result = fields?.map((field) => {
      // console.log(field, data[field?.name]);

      let filteredData = Object.only(
        data,
        fields.map((v) => v.name)
      );
      return {
        ...field,
        defaultValue: data[field?.name],
        // disabled: true,
      };
    });
    // console.log(result);
    return result;
  };

  const getCustomerApplicationInfo = () => {
    props.apis
      .apiGetCustomerSingleApplication({
        applicationId: applId,
      })
      .then((res) => {
        console.log({ res });
        setCustomerApplicationInfo(res);
        if (res) {
          let communicationAddressObj = res?.ApplicationAddressInfo?.filter(
            (v) => v?.residenceStatus === "RENTED"
          );
          let permanentAddressObj = res?.ApplicationAddressInfo?.filter(
            (v) => v?.residenceStatus === "OWNED"
          );
          // console.log({ communicationAddressObj, permanentAddressObj });

          if (communicationAddressObj) {
            let updatedCommunicationFields = updateFields(
              communicationFields,
              communicationAddressObj[0]
            );
            setCommunicationFields([...updatedCommunicationFields]);
          }
          if (permanentAddressObj) {
            let updatedPermanentFields = updateFields(permanentFields, {
              residenceStatusPM: permanentAddressObj[0]?.residenceStatus,
              pincodePM: permanentAddressObj[0]?.pincode,
              statePM: permanentAddressObj[0]?.state,
              cityPM: permanentAddressObj[0]?.city,
              landmarkPM: permanentAddressObj[0]?.landmark,
              address1PM: permanentAddressObj[0]?.address1,
              address2PM: permanentAddressObj[0]?.address2,
              address3PM: permanentAddressObj[0]?.address3,
              docTypePM: permanentAddressObj[0]?.docType,
              fileIdPM: permanentAddressObj[0]?.fileId,
            });
            setPermanentFields([...updatedPermanentFields]);
          }
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getCustomerApplicationInfo();
  }, []);

  return (
    <>
      <div className="container-fluid py-0">
        <AppForm className="mx-auto" onSubmit={handleAddressForm}>
          <Card className="form_group_card row">
            <div className="row mb-4">
              <h2 className="form_title red mb-3">Communication Address *</h2>
              {communicationFields.map((field, i) => {
                if (field.name === "spacer") {
                  return (
                    <div
                      className={`col-12 col-md-${field?.customcolspan || 4} ${
                        field?.columnClass || ""
                      }`}
                    ></div>
                  );
                } else {
                  return (
                    <div
                      className={`col-12 col-md-${field?.customcolspan || 4} ${
                        field?.columnClass || ""
                      }`}
                      id={`column-${field?.name}`}
                    >
                      <AppInput {...field}></AppInput>
                    </div>
                  );
                }
              })}
            </div>
          </Card>
          <Card className="form_group_card row">
            <div className="row mb-4">
              <h2 className="form_title red mb-3">Permanent Address</h2>
              {permanentFields.map((field, i) => {
                if (field.name === "spacer" || field.name === "spacerPM") {
                  return (
                    <div
                      className={`col-12 col-md-${field?.customcolspan || 4} ${
                        field?.columnClass || ""
                      }`}
                    ></div>
                  );
                } else {
                  return (
                    <div
                      className={`col-12 col-md-${field?.customcolspan || 4} ${
                        field?.columnClass || ""
                      }`}
                      id={`column-${field?.name}`}
                    >
                      <AppInput {...field}></AppInput>
                    </div>
                  );
                }
              })}
            </div>
          </Card>

          {isReadonly ||
          customerApplicationInfo?.ApplicationAddressInfo?.length > 0 ? (
            <div className="nav_container mt-3">
              <Link to={getBackLink()} className="btn btn-primary-lite me-3">
                Cancel
              </Link>
              <button
                type="button"
                onClick={(...args) => props.changeTab(...args, "incomedetails")}
                className="btn btn-primary"
              >
                Next
              </button>
            </div>
          ) : (
            <div className="nav_container mt-3">
              <Link to={getBackLink()} className="btn btn-primary-lite me-3">
                Cancel
              </Link>
              <button type="submit" className="btn btn-primary">
                Save & Next
              </button>
            </div>
          )}
        </AppForm>
      </div>
    </>
  );
};

export default constore(AddressDetails);
