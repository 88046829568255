import React, { useEffect, useState } from "react";

const AddressDetails = (props) => {
  const toastConfig = {
    autoClose: 3000,
    closeButton: false,
    hideProgressBar: true,
  };
  const [checkValue, setCheckValue] = useState("aadharCheck");
  const [editdata, setEditdata] = useState(props.editdata);
  const [aadharPincodes, setAadharPincodes] = useState({});

  const addressData =
    props.editdata?.CustomerAddressInfo.length > 0
      ? props.editdata?.CustomerAddressInfo[0]
      : {};
  const aadharData = props.editdata?.customerAadharCardInfo;
  const isAadharAddress =
    props.editdata?.CustomerAddressInfo[0]?.isAadharAddress;

  const [aadharFields, setAadharFields] = useState([
    {
      name: "address1",
      label: "Address 1",
      type: "text",
      placeholder: "Please enter",
      customcolspan: 12,
      readOnly: true,
    },
    {
      name: "pincode",
      label: "PIN code",
      type: "number",
      placeholder: "Please enter",
      maxlength: 6,
      readOnly: true,
    },
    {
      name: "state",
      label: "State",
      type: "text",
      placeholder: "Please enter",
      readOnly: true,
    },
    {
      name: "city",
      label: "City",
      type: "text",
      placeholder: "Please enter",
      readOnly: true,
    },
    {
      name: "landmark",
      label: "Landmark",
      type: "text",
      placeholder: "Please enter",
    },
    // {
    //   name: "addressType",
    //   label: "Address Proof Type",
    //   type: "select",
    //   options: _enum.ADDRESS_TYPES,
    //   defaultValue: props?.editdata?.addressType || "",
    //   placeholder: "Please enter",
    //   required: true,
    // },
    // {
    //   name: "docType",
    //   label: "Document Type",
    //   type: "select",
    //   options: _enum.DOC_TYPES_NEW,
    //   placeholder: "Please enter",
    //   required: true,
    // },
    // {
    //   name: "fileId",
    //   label: "Document Upload",
    //   fileLabel: "Upload document",
    //   foldername: "kycDocs",
    //   type: "file",
    //   placeholder: "Please enter",
    // },
  ]);

  const [addressFields, setAddressFields] = useState([
    {
      name: "address1",
      label: "Address 1",
      type: "text",
      placeholder: "Please enter",
      customcolspan: 12,
      required: true,
    },
    {
      name: "address2",
      label: "Address 2",
      type: "text",
      placeholder: "Please enter",
      customcolspan: 12,
    },
    {
      name: "address3",
      label: "Address 3",
      type: "text",
      placeholder: "Please enter",
      customcolspan: 12,
    },
    {
      name: "pincode",
      label: "PIN code",
      type: "number",
      onChange: (...args) => handleAddressPincode(...args),
      placeholder: "Please enter",
      required: true,
    },
    {
      name: "state",
      label: "State",
      type: "text",
      placeholder: "Please enter",
      readOnly: true,
    },
    {
      name: "city",
      label: "City",
      type: "text",
      placeholder: "Please enter",
      readOnly: true,
    },
    {
      name: "landmark",
      label: "Landmark",
      type: "text",
      placeholder: "Please enter",
    },
    // {
    //   name: "addressType",
    //   label: "Address Proof Type",
    //   type: "select",
    //   options: _enum.ADDRESS_TYPES,
    //   placeholder: "Please enter",
    //   required: true,
    // },
    {
      name: "docType",
      label: "Document Type",
      type: "select",
      options: _enum.DOC_TYPES_NEW,
      placeholder: "Please enter",
      required: true,
    },
    {
      name: "fileId",
      label: "Document Upload",
      fileLabel: "Upload document",
      foldername: "kycDocs",
      type: "file",
      accept: ".png, .jpg, .jpeg",
      placeholder: "Please enter",
    },
  ]);

  const handleAddressPincode = (e) => {
    let value = e.target.value;
    if (value.length >= 6) {
      getAddressPincode(value);
    }
  };

  const getAadharCityState = (pincode) => {
    let payload = {
      pincode,
    };
    props.apis.apiGetIndividualByPincode(payload).then((res) => {
      console.log({ res });
      if (res) {
        setAadharPincodes(res);
      }
    });
  };

  const getAddressPincode = (pincode) => {
    let payload = {
      pincode,
    };
    console.log({ payload });
    mixins.debounce(() => {
      props.apis.apiGetIndividualByPincode(payload).then((res) => {
        console.log({ res, checkValue });

        console.log({
          checkValue,
          addressData,
        });
        var formdata = {};

        if (editdata) {
          formdata = {
            ...Object.except(addressData, ["pincode", "city", "state"]),
            pincode: pincode,
            city: res?.city || "",
            state: res?.state || "",
          };
        } else {
          formdata = {
            ...Object.except(addressData, ["pincode", "city", "state"]),
            pincode: pincode,
            city: res?.city || "",
            state: res?.state || "",
          };
        }
        console.log({ formdata });
        if (Object.keys(formdata).length > 0) {
          let updatedFields = updateFields(addressFields, formdata);
          setAddressFields([...updatedFields]);
          // getAddressPincode(formdata?.pincode);
        }
      });
    }, 2000);
  };

  const handleCommunicationAddress = (e) => {
    e.persist();
    let value = e.target.value;
    console.log({ value });

    setCheckValue(value);
  };

  useEffect(() => {
    var formdata = {};
    if (checkValue === "aadharCheck") {
      formdata = {
        ...Object.except(addressData, ["address1", "pincode", "city", "state"]),
        address1: aadharData?.address,
        pincode: aadharData?.pincode,
        city: aadharData?.city,
        state: aadharData?.state,
      };
    }
    if (checkValue === "addressCheck") {
      formdata = {
        ...addressData,
      };
    }
    if (Object.keys(formdata).length > 0) {
      if (checkValue === "aadharCheck") {
        let updatedFields = updateFields(aadharFields, formdata);
        setAadharFields([...updatedFields]);
      }

      if (checkValue === "addressCheck") {
        let updatedFields = updateFields(addressFields, formdata);
        setAddressFields([...updatedFields]);
        // getAddre ssPincode(formdata?.pincode);
      }
    }
  }, [checkValue, aadharPincodes]);

  useEffect(() => {
    if (editdata) {
      setCheckValue(
        Object.keys(addressData).length > 0 ? "addressCheck" : "aadharCheck"
      );
    }
  }, [editdata]);

  const updateFields = (fields, data) => {
    let result = fields?.map((field) => {
      if (field.type === "file") {
        // console.log({ data, field });
        field.defaultValue = data?.fileId;
        field.value = data?.fileId;
        // field.disabled = true;
        return {
          ...field,
        };
      } else if (!editdata && field.name === "address1") {
        return {
          ...field,
          defaultValue: data["address"],
        };
      } else {
        return {
          ...field,
          defaultValue: data[field?.name],
          // disabled: true,
        };
      }
    });
    return result;
  };

  const onCommunicationSubmit = (e, data) => {
    let payload = {
      ...Object.except(data, ["communicationAddress"]),
      isAadharAddress: checkValue === "aadharCheck" ? true : false,
    };
    console.log({ payload });

    const resolve = () => {
      props.store("user", {
        ...props?.$store?.user,
        CustomerAddressInfo: [
          {
            ...Object.except(payload, ["fileName"]),
          },
        ],
      });
    };
    if (Object.keys(addressData)?.length > 0) {
      console.log("edit data");
      let addressId = addressData.addressId;
      props.apis
        .apiUpdateCustomerAddressInfo(payload, { addressId: addressId })
        .then((res) => {
          console.log({ res });
          if (res) {
            resolve();
            toast.success("KYC Details Updated Successfully", toastConfig);
            props.skipToStep(3);
          }
        });
    } else {
      console.log("add data");
      props.apis.apiAddCustomerAddressInfo(payload).then((res) => {
        console.log({ res });
        if (res) {
          resolve();
          toast.success("KYC Details Verified Successfully", toastConfig);
          props.skipToStep(3);
        }
      });
    }
  };

  // ----------------------------------------------------------------

  useEffect(() => {
    if (aadharData !== null) {
      getAadharCityState(aadharData?.pincode);
    }
  }, []);

  props.$setNode({
    props,
  });
  return (
    <>
      {/* COMMUNICATION ADDRESS */}
      <AppForm onSubmit={onCommunicationSubmit}>
        <AppInput
          name="residenceStatus"
          label="Residence Status"
          type="select"
          defaultValue={addressData?.residenceStatus || ""}
          // disabled={hasEditData}
          options={_enum.RESIDENCE_TYPES}
          required={true}
        ></AppInput>
        <h2 className="form_title mb-3">Communication Address</h2>
        <div
          className={Object.className({
            "checkbox_container mb-4": true,
            // "pe-none": hasEditData,
          })}
        >
          <div class="form-check me-3">
            <input
              class="form-check-input"
              type="radio"
              value="aadharCheck"
              name="communicationAddress"
              id="aadharCheck"
              onChange={(...args) => handleCommunicationAddress(...args)}
              checked={checkValue === "aadharCheck"}
            />
            <label class="form-check-label" for="aadharCheck">
              Same as Aadhar
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              value="addressCheck"
              name="communicationAddress"
              id="addressCheck"
              onChange={(...args) => handleCommunicationAddress(...args)}
              checked={checkValue === "addressCheck"}
            />
            <label class="form-check-label" for="addressCheck">
              New Address
            </label>
          </div>
        </div>
        {checkValue === "aadharCheck" &&
          aadharFields?.map((field, i) => {
            return (
              <div
                className={`col-12 col-md-${field?.customcolspan || 6}`}
                key={i}
              >
                <AppInput {...field}></AppInput>
              </div>
            );
          })}
        {checkValue === "addressCheck" &&
          addressFields?.map((field, i) => {
            return (
              <div
                className={`col-12 col-md-${field?.customcolspan || 6}`}
                key={i}
              >
                <AppInput {...field}></AppInput>
              </div>
            );
          })}
        <div className="nav_container mt-4">
          <button
            type="button"
            className="btn btn-primary-lite me-3"
            // onClick={() => props.handleSelect("pan")}
            onClick={() => props.onClose()}
          >
            Cancel
          </button>
          {editdata ? (
            <button type="submit" className="btn btn-primary me-3">
              Update & Next
            </button>
          ) : (
            <button type="submit" className="btn btn-primary">
              Submit & Next
            </button>
          )}
        </div>
      </AppForm>
    </>
  );
};

export default constore(AddressDetails);
