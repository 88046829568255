import Leads from "../../../jsx/components/Dashboard/Leads/leads";

const LeadsWrapper = () => {
  return <Leads />;
};

export const allowedRoles = [
  _enum.ROUTE_PERSONAS.admin,
  _enum.ROUTE_PERSONAS.ops,
  _enum.ROUTE_PERSONAS.sales,
  _enum.ROUTE_PERSONAS.sales_lead,
];
export default constore(LeadsWrapper);
