import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { BankNamesModal } from "./BankNamesModal";

const BankNames = (props) => {
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [modalFields, setModalFields] = useState([
    {
      label: "Bank Name",
      name: "bankName",
      type: "text",
      placeholder: "Please Enter",
      required: true,
    },
    {
      label: "Upload Logo",
      name: "fileId",
      type: "file",
      accept: ".png, .jpg, .jpeg",
      foldername: "bankNamesFolder",
    },
  ]);
  const [editData, setEditData] = useState([]);
  const [editmode, setEditmode] = useState(false);
  const [viewmode, setViewmode] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [popupfields, setPopupfields] = useState({});
  const [popupdata, setPopupdata] = useState({});

  const [rowcount, setRowcount] = useState(0);
  const columns = [
    {
      dataIndex: "bankName",
      title: "Bank Name",
      render: (text, record) => {
        return <span className="text-primary">{text}</span>;
      },
    },
    {
      dataIndex: "fieldId",
      title: "Logo",
      render: (text, record) => {
        return (
          <a
            className="link_btn"
            href={downloadFile(record.fileId)}
            target="_blank"
          >
            Download
          </a>
        );
      },
    },
  ];

  const onPageChange = (pageData) => {
    console.log({ onPageChange: pageData });
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const openModal = () => {
    setOpenCanvas(true);
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };

  const getBankNames = () => {
    let payload = {
      ...search,
    };
    // props.apis.apiGetBanknames(payload).then(({ data, count }) => {
    //   console.log({ apiGetBanknames: data });
    //   setTableData(data);
    //   setIsFetching(false);
    //   setRowcount(count);
    // });
    setTableData([]);
    setIsFetching(false);
  };

  const deleteBankName = (record) => {
    console.log({ deleteBankName: record.bankId });
    swal2
      .fire({
        title: "Are you sure you want to delete this location?",
        icon: "warning",
        iconColor: "#FF4D4D",
        showCancelButton: true,
        confirmButtonColor: "#FF4D4D",
        cancelButtonColor: "##D6DBE7",
        confirmButtonText: "Yes, delete it!",
      })
      .then((result) => {
        if (result.isConfirmed) {
          // props.apis
          //   .apiDeleteBankname({ bankId: record.bankId })
          //   .then((res) => {
          //     console.log({ apiDeleteBankname: res });
          //     swal2.fire({
          //       title: "Bank Name Deleted",
          //       text: "Bank Name deleted successfully !!",
          //       icon: "success",
          //       iconColor: "#FF4D4D",
          //       timer: 4000,
          //     });
          //     getBankNames();
          //   });
        }
      });
  };

  const viewBankName = (record) => {
    setEditmode(false);
    setViewmode(true);
    // GET BANK-NAMES BY ID
    // props.apis.apiGetBanknameById({ bankId: record.bankId }).then((data) => {
    //   let filteredData = Object.only(
    //     data,
    //     modalFields.map((v) => v.name)
    //   );
    //   setPopupdata(filteredData);
    //   setEditData(data);
    //   openModal();
    // });
  };

  const editBankName = (record) => {
    setEditmode(true);
    setViewmode(false);
    // GET BANK NAME BY ID
    // props.apis.apiGetBanknameById({ bankId: record.bankId }).then((data) => {
    //   let filteredData = Object.only(
    //     data,
    //     modalFields.map((v) => v.name)
    //   );
    //   setPopupdata(filteredData);
    //   setEditData(data);
    //   openModal();
    // });
  };

  const handleSubmit = (e, data, form) => {
    e.preventDefault();
    let formdata = data;
    let payload = {
      ...formdata,
    };
    console.log({ payload });

    // if (editmode) {
    //   props.apis
    //     .apiUpdateBankname(payload, {
    //       bankId: editData?.bankId,
    //     })
    //     .then((res) => {
    //       console.log({ apiUpdateBankname: res });
    //       swal2.fire({
    //         title: "Bank Name updated successfully.",
    //         icon: "success",
    //         iconColor: "#FF4D4D",
    //         timer: 4000,
    //       });
    //       closeModal();
    //       getBankNames();
    //     });
    // } else {
    //   props.apis.apiAddBankname(payload).then((res) => {
    //     console.log({ apiAddBankname: res });
    //     swal2.fire({
    //       title: "Bank Name added successfully.",
    //       icon: "success",
    //       iconColor: "#FF4D4D",
    //       timer: 4000,
    //     });
    //     closeModal();
    //     getBankNames();
    //   });
    // }
  };

  const downloadFile = (fileId) => {
    let authToken = props.$store.auth;
    // console.log({ authToken: authToken });
    // return props.apis.apiGetFile({ auth: authToken }, { fileId: fileId });
  };

  const addModalFields = (values) => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    setPopupfields(fields);
    setPopupdata(formdata);
    setOpenCanvas(true);
  };

  const onModalClose = () => {
    console.log("onModalClose");
    closeModal();
    setPopupfields({});
    setViewmode(false);
    setEditmode(false);
    setEditData({});

    let fileInput = document.querySelector(
      `[name="appinput-fileId"] .filevalue_container`
    );
    if (editmode === false && viewmode === false) {
      console.log("clear upload data");
      if (fileInput && fileInput.value.length > 0) {
        // props.apis
        //   .apiFileRemover({ fileId: fileInput.value })
        //   .then((res) => {
        //     console.log({ apiFileRemover: res });
        //   })
        //   .catch((err) => {
        //     console.log({ err });
        //   });
      }
    }
  };

  useEffect(() => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    // console.log({ updatedfields: fields });
    setPopupfields(fields);
  }, [editData]);

  useEffect(() => {
    console.log({ Banknames: props });
    getBankNames();
  }, []);

  return (
    <>
      <div className="page_header mb-3 text-end">
        <Link
          to={"#"}
          className="btn btn-primary btn-sm ms-1"
          onClick={(...args) => addModalFields(...args)}
        >
          + Add New
        </Link>
      </div>
      <AppTable
        data={tableData}
        columns={columns}
        onChange={(...arg) => onPageChange(...arg)}
        total={rowcount}
        reorder={true}
        editAction={(e) => editBankName(e)}
        viewAction={(e) => viewBankName(e)}
        deleteAction={(e) => deleteBankName(e)}
        loading={isFetching}
      ></AppTable>

      <BankNamesModal
        id="banknamesCanvas"
        title={`${
          editmode
            ? "Edit Bank Names"
            : viewmode
            ? "View Bank Names"
            : "Add Bank Names"
        }`}
        show={openCanvas}
        onClose={() => onModalClose()}
        data={popupdata}
        editdata={editData}
        fields={popupfields}
        editmode={editmode}
        onSubmit={(...args) => handleSubmit(...args)}
        submitText={`${editmode ? "Update" : "Submit"}`}
        disableallfields={viewmode ? true : false}
      />
    </>
  );
};

export default constore(BankNames);
