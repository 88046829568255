import Loandetailsactiveview from "../../../jsx/components/Dashboard/LoanDetails/loandetailsactiveview";

const LoanDetailsActiveViewWrapper = () => {
  return <Loandetailsactiveview />;
};

export const allowedRoles = [
  _enum.ROUTE_PERSONAS.admin,
  _enum.ROUTE_PERSONAS.collections,
  _enum.ROUTE_PERSONAS.senior_underwriter,
  _enum.ROUTE_PERSONAS.underwriter,
];
export default constore(LoanDetailsActiveViewWrapper);
