import React, { useEffect, useRef, useState } from "react";
import DocsCard from "../../MyProfile/DocsCard";
import FlexCard from "../Helpers/FlexCard";
import { Link } from "react-router-dom";
import moment from "moment";
import DocsModal from "./DocsModal";
import AddressModal from "./AddressModal";

const PersonalInfo = (props) => {
  var isReadonly = props.urlparams.params.readonly === "true" ? true : false;
  var tabStatus = props.urlparams.params.status;
  const frompath = props?.urlparams?.params?.frompath;
  const [empdata, setEmpdata] = useState({});
  const [myinfo, setMyinfo] = useState({});
  const [maindata, setMaindata] = useState({});
  const [proofdata, setProofdata] = useState({});
  const [cmAddressInfo, setCmAddressInfo] = useState({});
  const [pmAddressInfo, setPmAddressInfo] = useState({});
  const [customerDocsInfo, setCustomerDocsInfo] = useState({});
  const [communicationAddressData, setCommunicationAddressData] = useState({});
  const [permanentAddressData, setPermanentAddressData] = useState({});
  const [addressModalType, setAddressModalType] = useState("");
  const [additionalDocs, setAdditionalDocs] = useState([]);
  const additionalDocForm = useRef(null);
  // ----------------------------------------------------------------

  const [modalFields, setModalFields] = useState([
    {
      label: "Residence Status",
      name: "residenceStatus",
      type: "select",
      options: _enum.RESIDENCE_TYPES,
    },
    {
      name: "pincode",
      label: "PIN code",
      type: "number",
      placeholder: "Please enter",
      maxlength: 6,
    },
    {
      name: "city",
      label: "City",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "state",
      label: "State",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "docType",
      label: "Document Type",
      type: "select",
      options: _enum.DOC_TYPES_NEW_WITH_OTHERS,
    },
    {
      name: "fileId",
      label: "Document Upload",
      fileLabel: "Upload document",
      foldername: "postSanctionedDocs",
      type: "file",
      accept: ".png, .jpg, .jpeg",
      placeholder: "Please enter",
    },
    {
      name: "landmark",
      label: "Landmark",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "address1",
      label: "Address 1",
      type: "text",
      placeholder: "Please enter",
      customcolspan: 12,
    },
    {
      name: "address2",
      label: "Address 2",
      type: "text",
      placeholder: "Please enter",
      customcolspan: 12,
    },
    {
      name: "address3",
      label: "Address 3",
      type: "text",
      placeholder: "Please enter",
      customcolspan: 12,
    },
  ]);
  const [editData, setEditData] = useState([]);
  const [editmode, setEditmode] = useState(false);
  const [viewmode, setViewmode] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [popupfields, setPopupfields] = useState({});
  const [popupdata, setPopupdata] = useState({});
  const [errorMessage, setErrorMessage] = useState({ name: "", message: "" });
  const [queryinfo, setQueryinfo] = useState([]);

  const openModal = () => {
    setOpenCanvas(true);
    addModalFields();
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };

  const onModalClose = () => {
    console.log("onModalClose");
    closeModal();
    setPopupfields({});
    setViewmode(false);
    setEditmode(false);
    setEditData({});
    setErrorMessage({ name: "", message: "" });
  };

  const addModalFields = (values) => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    setPopupfields(fields);
    setPopupdata(formdata);
    setOpenCanvas(true);
  };

  const editCommunicationAddress = (e, record, modalTitle) => {
    setEditmode(true);
    setViewmode(false);
    setAddressModalType(modalTitle);

    let filteredData = Object.only(
      record,
      modalFields.map((v) => v.name)
    );
    console.log({ record, filteredData });

    setPopupdata(filteredData);
    setEditData(record);
    let updatedFields = modalFields?.map((field) => {
      return {
        ...field,
        defaultValue: filteredData[field?.name],
      };
    });
    setModalFields([...updatedFields]);
    openModal();
  };

  useEffect(() => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    // console.log({ updatedfields: fields });
    setPopupfields(fields);
  }, [editData]);

  const handleSubmit = (e, data, form) => {
    e.preventDefault();
    let payload = {
      ...data,
    };
    console.log({ payload, editData });

    props.apis
      .apiUpdateUnderWriterApplicationAddress(payload, {
        addressId: editData?.addressId,
      })
      .then((res) => {
        console.log({ apiUpdateEmployee: res });
        swal2.fire({
          title: `${
            addressModalType === "cmAddress" ? "Communication" : "Permanent"
          } address updated successfully.`,
          icon: "success",
          iconColor: "#FF4D4D",
          timer: 4000,
        });
        closeModal();
        getUnderwriterInfo();
      })
      .catch((error) => console.log(error));
  };
  // ----------------------------------------------------------------

  const getQueries = () => {
    props.apis
      .apiUnderWriterGetApplicationQuery({
        applicationNumber: props.urlparams.params.id,
      })
      .then((res) => {
        let obj = Object.groupBy(res, (item) => {
          return item.category;
        });

        setQueryinfo(obj["PERSONAL_INFO"] || []);
        // prepareSummaryInfo(res);
      });
  };

  // ----------------------------------------------------------------

  const [modalDocsFields, setModalDocsFields] = useState([
    {
      name: "panFileId",
      label: "Upload PAN Card",
      type: "file",
      fileLabel: "Upload PAN Card",
      foldername: "underwriterDocs",
      accept: ".png, .jpg, .jpeg",
    },
    {
      name: "aadharFrontFileId",
      label: "Upload Aadhar Card (Front)",
      type: "file",
      fileLabel: "Upload Aadhar Card",
      foldername: "underwriterDocs",
      accept: ".png, .jpg, .jpeg",
    },
    {
      name: "aadharBackFileId",
      label: "Upload Aadhar Card (Back)",
      type: "file",
      fileLabel: "Upload Aadhar Card",
      foldername: "underwriterDocs",
      accept: ".png, .jpg, .jpeg",
    },
    {
      name: "photoFileId",
      label: "Live Photo",
      type: "file",
      fileLabel: "Live Photo",
      foldername: "underwriterDocs",
      accept: ".png, .jpg, .jpeg",
    },
  ]);
  const [editDocsData, setEditDocsData] = useState([]);
  const [editDocsmode, setEditDocsmode] = useState(false);
  const [viewDocsmode, setViewDocsmode] = useState(false);
  const [openDocsCanvas, setOpenDocsCanvas] = useState(false);
  const [popupDocsfields, setPopupDocsfields] = useState({});
  const [popupDocsdata, setPopupDocsdata] = useState({});
  // const [errorMessage, setErrorMessage] = useState({ name: "", message: "" });

  const openDocsModal = () => {
    setOpenDocsCanvas(true);
    addDocsModalFields();
  };

  const closeDocsModal = () => {
    setOpenDocsCanvas(false);
  };

  const onDocsModalClose = () => {
    console.log("onModalClose");
    closeDocsModal();
    setPopupDocsfields({});
    setViewDocsmode(false);
    setEditDocsmode(false);
    setEditDocsData({});
    // setErrorMessage({ name: "", message: "" });
  };

  const addDocsModalFields = (values) => {
    let fields = (modalDocsFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    setPopupDocsfields(fields);
    setPopupDocsdata(formdata);
    setOpenDocsCanvas(true);
  };

  const editAdditionalDocs = (e, record) => {
    setEditDocsmode(true);
    setViewDocsmode(false);

    let filteredData = Object.only(
      record,
      modalDocsFields.map((v) => v.name)
    );
    console.log({ record, filteredData });

    setPopupDocsdata(filteredData);
    setEditDocsData(record);
    let updatedFields = modalDocsFields?.map((field) => {
      return {
        ...field,
        defaultValue: filteredData[field?.name],
      };
    });
    setModalDocsFields([...updatedFields]);
    openDocsModal();
  };

  useEffect(() => {
    let fields = (modalDocsFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    // console.log({ updatedfields: fields });
    setPopupDocsfields(fields);
  }, [editDocsData]);

  const handleDocsSubmit = (e, data, form) => {
    e.preventDefault();
    let payload = {
      ...data,
    };
    console.log({ payload });

    props.apis
      .apiUpdateCustomerDocsByUnderwriter(
        Object.except(payload, ["fileName"]),
        {
          customerId: empdata?.customerId,
        }
      )
      .then((res) => {
        console.log({ updateDocs: res });
        swal2.fire({
          title: "Documents updated successfully.",
          icon: "success",
          iconColor: "#FF4D4D",
          timer: 4000,
        });
        closeDocsModal();
        getUnderwriterInfo();
      })
      .catch((error) => console.log(error));
  };

  // ----------------------------------------------------------------

  const getUnderwriterInfo = () => {
    props.apis
      .apiGetUnderWriterApplicationDetails({
        applicationNumber: props.urlparams.params.id,
      })
      .then((res) => {
        if (res) {
          console.log({ res });
          props.onGetName(res.customerName);
          setEmpdata(res);
          setMyinfo({
            panFileMetaData: {
              fileId: res?.customer?.panFileId,
            },
            aadharFrontFileMetaData: {
              fileId: res?.customer?.aadharFrontFileId,
            },
            aadharBackFileMetaData: {
              fileId: res?.customer?.aadharBackFileId,
            },
            photoFileMetaData: {
              fileId: res?.customer?.photoFileId,
            },
          });
          setCustomerDocsInfo({
            panFileId: res?.customer?.panFileId,
            aadharFrontFileId: res?.customer?.aadharFrontFileId,
            aadharBackFileId: res?.customer?.aadharBackFileId,
            photoFileId: res?.customer?.photoFileId,
          });
          setMaindata({
            user_id: res?.customerId,
            gender: res?.customer?.gender,
            dob: moment(res?.customer?.dob)?.format("DD-MM-YYYY"),
            age: moment().diff(res?.customer?.dob, "years"),
            mobileNumber: res?.customer?.mobileNumber,
            alternateMobileNumber: res?.customer?.alternateMobileNumber,
            emailAddress: res?.customer?.emailAddress,
            pincode: res?.customer?.customerAadharCardInfo?.pincode,
            aadhar_no: `XXXX-XXXX-${res?.customer?.customerAadharCardInfo?.uid}`,
            pan_number: res?.customer?.customerPanCardInfo?.number,
            aadhar_address: res?.customer?.customerAadharCardInfo?.address,
            customer_type: res?.customer?.customerType?.toTitleCase(),
          });
          setProofdata({
            name_as_per_application: res.customerName,
            name_as_per_aadhar_card:
              res?.customer?.customerAadharCardInfo?.name,
            name_as_per_pan_card: res?.customer?.customerPanCardInfo?.name,
            aadhar_seeding_successful:
              res?.customer?.customerPanCardInfo?.aadhaarSeedingStatus,
          });
          if (res?.additionalDocsSubmitted) {
            setAdditionalDocs(
              res?.additionalDocsSubmitted?.filter(
                (v) => v.category === "PERSONAL"
              )
            );
          }
          let communicationAddrObj = res?.ApplicationAddressInfo?.filter(
            (v) => v.addressType === "COMMUNICATION"
          )[0];
          let cmAddr1 = communicationAddrObj?.address1?.toTitleCase();
          let cmAddr2 = communicationAddrObj?.address2?.toTitleCase();
          let cmAddr3 = communicationAddrObj?.address3?.toTitleCase();

          let communicationAddrData = {
            residenceStatus:
              communicationAddrObj?.residenceStatus?.toTitleCase() || "-",
            state: communicationAddrObj?.state,
            pincode: communicationAddrObj?.pincode,
            docType: _enum.DOC_TYPES_MAP[communicationAddrObj?.docType],
            fileId: communicationAddrObj?.fileId,
            city: communicationAddrObj?.city,
            address: `${cmAddr1 ? cmAddr1 + "," : ""} ${
              cmAddr2 ? cmAddr2 + "," : ""
            } ${cmAddr3 ? cmAddr3 + "." : ""}`,
            colspan_key: "col-12 col-lg-6",
            colspan_value: "col-12 col-lg-6",
          };
          setCommunicationAddressData(communicationAddrData);
          setCmAddressInfo(
            res?.ApplicationAddressInfo?.filter(
              (v) => v.addressType === "COMMUNICATION"
            )[0]
          );
          // ----------------------------------------------------------------
          let permanentAddrObj = res?.ApplicationAddressInfo?.filter(
            (v) => v.addressType === "PERMANENT"
          )[0];
          let pmAddr1 = permanentAddrObj?.address1?.toTitleCase();
          let pmAddr2 = permanentAddrObj?.address2?.toTitleCase();
          let pmAddr3 = permanentAddrObj?.address3?.toTitleCase();

          let permanentAddrData = {
            residenceStatus:
              permanentAddrObj?.residenceStatus?.toTitleCase() || "-",
            state: permanentAddrObj?.state,
            pincode: permanentAddrObj?.pincode,
            docType: _enum.DOC_TYPES_MAP[permanentAddrObj?.docType],
            fileId: permanentAddrObj?.fileId,
            city: permanentAddrObj?.city,
            address: `${pmAddr1 ? pmAddr1 + "," : ""} ${
              pmAddr2 ? pmAddr2 + "," : ""
            } ${pmAddr3 ? pmAddr3 + "." : ""}`,
            colspan_key: "col-12 col-lg-6",
            colspan_value: "col-12 col-lg-6",
          };
          setPermanentAddressData(permanentAddrData);
          setPmAddressInfo(
            res?.ApplicationAddressInfo?.filter(
              (v) => v.addressType === "PERMANENT"
            )[0]
          );
        }
      });
  };
  useEffect(() => {
    getUnderwriterInfo();
    getQueries();
  }, []);

  const handleDecision = (e, data) => {
    let parsedPayload = parseInput(data);
    // console.log("formData", data, parsedPayload);
    let payload = {
      ...parsedPayload,
      applicationId: parseInt(props.urlparams.params.id),
      category: "PERSONAL_INFO",
    };
    console.log({ payload });
    props.apis
      .apiUnderWriterCreateApplicationQuery(payload)
      .then((res) => {
        swal2.fire({
          title: "Underwriter decision updated successfully.",
          icon: "success",
          iconColor: "#FF4D4D",
          timer: 4000,
        });
        props.changeTab(e, "incomedetails");
      })
      .catch((error) => {});
  };

  const getBankLink = () => {
    let link;
    if (frompath === "loanapplications") {
      link = `/app/loanapplications?activetab=${tabStatus}`;
    }
    if (frompath === "dupecheck") {
      link = `/app/dupecheck`;
    }
    return link;
  };

  // ----------------------------------------------------------------

  const [additionalDocFields, setAdditionalDocFields] = useState([
    {
      docType: "",
      fileName: "",
      fileId: "",
    },
  ]);
  const addMoreDocs = () => {
    setAdditionalDocFields([
      ...additionalDocFields,
      {
        docType: "",
        fileName: "",
        fileId: "",
      },
    ]);
  };
  const deleteDocField = (params, index, id) => {
    // console.log({ id });
    if (id !== "") {
      let payload = {
        fileId: id,
        applicationId: parseInt(props.urlparams.params.id),
      };
      // console.log({ payload });

      props.apis.apiUnderWriterDeleteAdditionalDocs(payload).then((res) => {
        getUnderwriterInfo();
      });
    } else {
      let data = [...additionalDocFields];
      data.splice(index, 1);
      setAdditionalDocFields(data);
    }
  };

  const handleAdditionalDocsChange = (args, index) => {
    var documentNameInputCol = document.querySelector(
      `#column${index}-fileName`
    );
    var documentNameInput = document.querySelector(
      `#column${index}-fileName [name="additionalDoc.${index}.fileName"]`
    );

    // console.log(documentNameInputCol, documentNameInput);
    const hasOthers = args?.some((obj) => obj.key === "OTHERS");
    if (hasOthers) {
      documentNameInputCol.classList.remove("d-none");
      documentNameInput.setAttribute("required", true);
    } else {
      documentNameInputCol.classList.add("d-none");
      documentNameInput.removeAttribute("required");
    }
  };

  const resolveResponse = (msg) => {
    swal2.fire({
      title: msg,
      icon: "success",
      iconColor: "#DE3232",
      confirmButtonColor: "#DE3232",
      confirmButtonText: "Close",
      timer: 3000,
    });
  };
  const handleAdditionalDocsSubmit = async (e, data) => {
    e.preventDefault();
    if (data) {
      let parsedPayload = parseInput(data);
      let payload = parsedPayload?.additionalDoc?.map((item) => {
        return {
          ...item,
          fileName: item?.fileName || item?.docType?.toTitleCase(),
          docType: item?.docType,
          category: "PERSONAL",
        };
      });

      const finalPayload = [];

      for (const obj of payload) {
        let reqdata = {
          file: obj.fileId,
        };
        let filePayload = {
          foldername: "underwriterDocs",
          applicationId: parseInt(props.urlparams.params.id),
          category: "PERSONAL",
        };

        const response = await props.apis
          .apiFileUploader(reqdata, filePayload)
          .catch((err) => {
            if (err) {
              console.log({ err });
            }
          });
        const uploadFileResData = await response;

        const key = Object.keys(uploadFileResData)[0];
        const value = uploadFileResData[key];
        const newObj = { ...obj, fileId: value };

        finalPayload.push(newObj);
      }
      if (finalPayload) {
        let applicationId = parseInt(props.urlparams.params.id);
        props.apis
          .apiUpdateUnderWriterAdditionalDocs(finalPayload, {
            applicationId: applicationId,
          })
          .then((res) => {
            resolveResponse("Additional Documents Added Successfully");
            additionalDocForm?.current?.form?.current?.reset();
            getUnderwriterInfo();
            setAdditionalDocFields([
              {
                docType: "",
                fileName: "",
                fileId: "",
              },
            ]);
          });
      }
    }
  };

  // ----------------------------------------------------------------
  props.$setNode({
    props,
    maindata,
    communicationAddressData,
    permanentAddressData,
  });
  return (
    <>
      <div className="card employee_card">
        <div className="card-body">
          <div className="card_header">
            <div className="avatar_card">
              <div className="me-3">
                <img
                  src={imagepaths.fakeUserIcon}
                  className="avatar"
                  alt="avatar"
                />
              </div>
              <div className="content">
                <h3 className="title">{empdata?.customerName || "-"}</h3>
              </div>
            </div>
          </div>
          <hr />
          <div className="card_body row mx-auto">
            <FlexCard
              data={maindata}
              verifiedIconsFor={["mobile_no", "email_id"]}
            />
          </div>
        </div>
      </div>
      <div className="card p-4">
        <FlexCard data={proofdata} />
      </div>
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="card p-4">
            <FlexCard
              title="Communication Address"
              data={
                communicationAddressData
                  ? {
                      ...Object.except(communicationAddressData, [
                        "colspan_key",
                        "colspan_value",
                      ]),
                    }
                  : null
              }
              hasFileId={
                communicationAddressData?.docType !== undefined ? true : false
              }
              fileIdKey="docType"
              editable={
                isReadonly ? false : tabStatus !== "inprogress" ? false : true
              }
              onEdit={(...args) =>
                editCommunicationAddress(...args, cmAddressInfo, "cmAddress")
              }
              colspan_key={
                communicationAddressData &&
                communicationAddressData?.colspan_key
              }
              colspan_value={
                communicationAddressData &&
                communicationAddressData?.colspan_value
              }
            />
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="card p-4">
            <FlexCard
              title="Permanent Address"
              data={
                permanentAddressData
                  ? {
                      ...Object.except(permanentAddressData, [
                        "colspan_key",
                        "colspan_value",
                      ]),
                    }
                  : null
              }
              hasFileId={
                permanentAddressData?.docType !== undefined ? true : false
              }
              fileIdKey="docType"
              editable={
                isReadonly ? false : tabStatus !== "inprogress" ? false : true
              }
              onEdit={(...args) =>
                editCommunicationAddress(...args, pmAddressInfo, "pmAddress")
              }
              colspan_key={
                permanentAddressData && permanentAddressData?.colspan_key
              }
              colspan_value={
                permanentAddressData && permanentAddressData?.colspan_value
              }
            />
          </div>
        </div>
      </div>
      <DocsCard
        title="Docs Details"
        editable={
          isReadonly ? false : tabStatus !== "inprogress" ? false : true
        }
        onEdit={(...args) => editAdditionalDocs(...args, customerDocsInfo)}
        data={myinfo}
      />

      {/* ADDITIONAL DOCS CARD */}
      <div className="card profile_card p-4">
        <div className="card-header p-0 border-0">
          <div className="d-flex align-items-center mb-3">
            <img
              src={imagepaths.cardGaurdIcon}
              alt="cardGaurdIcon"
              className="card_icon"
            />
            <h5 className="title ms-2 mb-0">Additional Documents</h5>
          </div>
        </div>
        <div className="py-4">
          <AppForm
            ref={additionalDocForm}
            onSubmit={(...args) => handleAdditionalDocsSubmit(...args)}
          >
            {additionalDocFields?.map((item, i) => (
              <div className="row" key={i}>
                <div className="col-12 col-md-3" id={`column${i}-docType`}>
                  <AppInput
                    name={`additionalDoc.${i}.docType`}
                    label="Document Type"
                    type="select"
                    options={_enum.DOC_TYPES_NEW_WITH_OTHERS}
                    onChange={(e) => handleAdditionalDocsChange(e, i)}
                    required
                  ></AppInput>
                </div>
                <div
                  className="col-12 col-md-3 d-none"
                  id={`column${i}-fileName`}
                >
                  <AppInput
                    name={`additionalDoc.${i}.fileName`}
                    label="Document Name"
                    type="text"
                    required
                  ></AppInput>
                </div>
                <div className="col-12 col-md-3" id={`column${i}-fileId`}>
                  <AppInput
                    name={`additionalDoc.${i}.fileId`}
                    label="Upload Document"
                    type="defaultfile"
                    fileLabel="Upload document"
                    foldername="underwriterDocs"
                    required
                  ></AppInput>
                </div>
                {i != 0 && (
                  <div
                    className={Object.className({
                      "col-12 col-md-3 d-flex align-items-center": true,
                    })}
                  >
                    <button
                      type="button"
                      onClick={(...args) =>
                        deleteDocField(...args, i, item?.fileId)
                      }
                      className={Object.className({
                        "btn btn-primary mt-4": true,
                        "d-none": isReadonly,
                      })}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </div>
                )}
              </div>
            ))}
            <div className="action_btn_group mb-4 d-flex align-items-center justify-content-between">
              <button
                type="button"
                className={Object.className({
                  "btn btn-primary-transparent p-0 fs-5 border-right-0": true,
                  "d-none": isReadonly,
                })}
                onClick={(...args) => addMoreDocs(...args)}
              >
                + Add more document
              </button>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </AppForm>

          {additionalDocs &&
            additionalDocs?.map((item, i) => {
              return (
                <div className="row" key={i}>
                  <div className="col-12 col-md-3">
                    <AppInput
                      name="docType"
                      label="Document Type"
                      defaultValue={item?.docType}
                      type="select"
                      options={_enum.DOC_TYPES_NEW_WITH_OTHERS}
                      required
                      disabled={true}
                    ></AppInput>
                  </div>
                  <div className="col-12 col-md-3">
                    <AppInput
                      name="fileName"
                      label="Document Name"
                      defaultValue={item?.fileName}
                      type="text"
                      required
                      disabled={true}
                    ></AppInput>
                  </div>
                  <div className="col-12 col-md-3">
                    <AppInput
                      name="fileId"
                      label="Upload Document"
                      type="file"
                      defaultValue={item?.fileId || ""}
                      fileLabel="Upload document"
                      foldername="underwriterDocs"
                      required
                      disabled={true}
                    ></AppInput>
                  </div>
                  <div
                    className={Object.className({
                      "col-12 col-md-3 d-flex align-items-center": true,
                    })}
                  >
                    <button
                      type="button"
                      onClick={(...args) =>
                        deleteDocField(...args, i, item?.fileId)
                      }
                      className={Object.className({
                        "btn btn-primary": true,
                        "d-none": isReadonly,
                      })}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      {isReadonly ? (
        <div className="nav_container mt-5">
          <Link to={getBankLink()} className="btn btn-primary-lite me-3">
            Cancel
          </Link>
          <button
            type="button"
            className="btn btn-primary"
            onClick={(...args) => props.changeTab(...args, "incomedetails")}
          >
            Next
          </button>
        </div>
      ) : (
        <div>
          {queryinfo
            .filter((q) => !q.responseId)
            .map((item) => (
              <>
                <div className="row">
                  <AppInput
                    name="uwDecision"
                    label="Underwriter Decision"
                    type="text"
                    defaultValue={item.uwDecision}
                    outerclass="col-12 col-md-3"
                    readOnly="true"
                  ></AppInput>

                  <AppInput
                    name="messageText"
                    label="Remarks"
                    readOnly="true"
                    defaultValue={item.messageText}
                    type="textarea"
                    outerclass="col-12 col-md-9 mb-3"
                  ></AppInput>
                </div>
              </>
            ))}
          <AppForm
            className="mx-auto"
            onSubmit={(...args) => handleDecision(...args)}
          >
            <AppInput
              name="uwDecision"
              label="Underwriter Decision"
              type="select"
              options={_enum.UNDER_WRITER_DECISION_DISCREPANCY}
              outerclass="col-12 col-md-3"
              required="true"
            ></AppInput>

            <AppInput
              name="messageText"
              label="Remarks"
              type="textarea"
              outerclass="col-12 col-md-9 mb-3"
            ></AppInput>
            <div className="nav_container mt-5">
              <Link
                to={`/app/loanapplications?activetab=${tabStatus}`}
                className="btn btn-primary-lite me-3"
              >
                Cancel
              </Link>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </AppForm>
        </div>
      )}
      <AddressModal
        id="employeeCanvas"
        title={`${
          editmode
            ? `Edit ${
                addressModalType === "cmAddress"
                  ? "Communication Address"
                  : addressModalType === "pmAddress"
                  ? "Permanent Address"
                  : ""
              }`
            : viewmode
            ? `View ${
                addressModalType === "cmAddress"
                  ? "Communication Address"
                  : addressModalType === "pmAddress"
                  ? "Permanent Address"
                  : ""
              }`
            : `Add ${
                addressModalType === "cmAddress"
                  ? "Communication Address"
                  : addressModalType === "pmAddress"
                  ? "Permanent Address"
                  : ""
              }`
        }`}
        show={openCanvas}
        onClose={() => onModalClose()}
        data={popupdata}
        editdata={editData}
        fields={popupfields}
        editmode={editmode}
        onSubmit={(...args) => handleSubmit(...args, addressModalType)}
        submitText={`${editmode ? "Update" : "Submit"}`}
        disableallfields={viewmode ? true : false}
        errorMessage={errorMessage}
      />
      <DocsModal
        id="employeeCanvas"
        title={`${
          editDocsmode
            ? "Edit Document Details"
            : viewDocsmode
            ? "View Document Details"
            : "Add Document Details"
        }`}
        show={openDocsCanvas}
        onClose={() => onDocsModalClose()}
        data={popupDocsdata}
        editdata={editDocsData}
        fields={popupDocsfields}
        editmode={editDocsmode}
        onSubmit={(...args) => handleDocsSubmit(...args)}
        submitText={`${editDocsmode ? "Update" : "Submit"}`}
        disableallfields={viewDocsmode ? true : false}
        // errorMessage={errorMessage}
      />
    </>
  );
};

export default constore(PersonalInfo);
