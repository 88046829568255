import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const NavHader = (props) => {
  const sideMenu = props.$store.sideMenu;
  const handleToogle = () => {
    props.store("sideMenu", !sideMenu);
    console.log("handleToogle");
    // dispatch(navtoggle());
  };
  return (
    <div className="nav-header">
      <Link to={props?.$store?.persona === _enum.ROUTE_PERSONAS.customer ? "/open/customer/login" : "/open/login"} className="brand-logo">
        <img
          src={imagepaths.loginWhiteBg}
          className="loginWhiteBg_img"
          alt="loginWhiteBg"
          style={{ width: "140px" }}
        />
        <img
          src={imagepaths.logoHome}
          className="logoHome_img"
          alt="logoHome"
          style={{ width: "35px" }}
        />
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          handleToogle();
        }}
      >
        <div className={`hamburger ${sideMenu ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default constore(NavHader);