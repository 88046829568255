import CreditScore from "../../../jsx/components/Dashboard/CreditScore/creditscore";

const CreditScoreWrapper = () => {
  return <CreditScore />;
};

export const allowedRoles = [
  _enum.ROUTE_PERSONAS.admin,
  _enum.ROUTE_PERSONAS.senior_underwriter,
];
export default constore(CreditScoreWrapper);
