import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const AuthGuard = ({ children, obj }) => {
    const userState = useSelector((state) => state?.store);
    let { persona, auth } = userState || {};
    let token = auth;
    let userRole = persona;
    let currentPath = window.location.pathname;
    let isOpenRoute = currentPath.includes("/open") ? true : false;
    let allowedRoles = obj?.allowedRoles || [];

    if (allowedRoles.length > 0 && !isOpenRoute && userRole) {
        console.log({ currentPath, allowedRoles, loggedUser: _enum.PERSONAS_REVERSE_MAP[userRole] });

        let isAllowedRoute = false;
        allowedRoles?.includes(userRole) ? isAllowedRoute = true : isAllowedRoute = false;

        // console.log({ token, isAllowedRoute, isOpenRoute });

        if (token && !isAllowedRoute && !isOpenRoute) {
            console.log("ROUTE NOT ALLOWED");
            localStorage.clear();
            return <Navigate to={persona === _enum.PERSONAS_REVERSE_MAP["CUSTOMER"] ? "/open/customer/login" : "/open/login"} />
        } else {
            return <>{children}</>
        }
    } else {
        return <>{children}</>
    }
}

export default constore(AuthGuard)