import Pendingapplications from "../../../jsx/components/Dashboard/PendingApplications/pendingapplications";

const PendingApplicatiionsWrapper = () => {
  return <Pendingapplications />;
};

export const allowedRoles = [
  _enum.ROUTE_PERSONAS.senior_underwriter,
  _enum.ROUTE_PERSONAS.underwriter,
];
export default constore(PendingApplicatiionsWrapper);
