import React from "react";
import Dupecheckdetailsview from "../../../jsx/components/Dashboard/DupeCheckDetailsView/dupecheckdetailsview";

const DupeCheckDetailsView = () => {
  return <Dupecheckdetailsview />;
};

export const allowedRoles = [
  _enum.ROUTE_PERSONAS.admin,
  _enum.ROUTE_PERSONAS.underwriter,
];
export default DupeCheckDetailsView;
