import Applications from "../../../jsx/components/Dashboard/Applications/applications";

const ApplicatiionsWrapper = () => {
  return <Applications />;
};

export const allowedRoles = [
  _enum.ROUTE_PERSONAS.ops,
  _enum.ROUTE_PERSONAS.sales,
  _enum.ROUTE_PERSONAS.sales_lead,
];
export default constore(ApplicatiionsWrapper);
