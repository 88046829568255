import Loandetailsclosedview from "../../../jsx/components/Dashboard/LoanDetails/loandetailsclosedview";

const LoanDetailsClosedViewWrapper = () => {
  return <Loandetailsclosedview />;
};

export const allowedRoles = [
  _enum.ROUTE_PERSONAS.admin,
  _enum.ROUTE_PERSONAS.collections,
  _enum.ROUTE_PERSONAS.senior_underwriter,
  _enum.ROUTE_PERSONAS.underwriter,
];
export default constore(LoanDetailsClosedViewWrapper);
