import Loandetailspendingview from "../../../jsx/components/Dashboard/LoanDetails/loandetailspendingview";

const LoanDetailsPendingViewWrapper = () => {
  return <Loandetailspendingview />;
};

export const allowedRoles = [
  _enum.ROUTE_PERSONAS.admin,
  _enum.ROUTE_PERSONAS.collections,
  _enum.ROUTE_PERSONAS.senior_underwriter,
  _enum.ROUTE_PERSONAS.underwriter,
];
export default constore(LoanDetailsPendingViewWrapper);
