export const AdminMenuList = [
  {
    title: "Dashboard",
    iconStyle: imagepaths.Dashboard,
    iconActive: imagepaths.DashboardActive,
    to: "/app/home",
    id: ["home", "portfolioinfo"],
  },
  {
    title: "Users",
    iconStyle: imagepaths.LeadsIcon,
    iconActive: imagepaths.LeadsIconActive,
    to: "/app/employees",
    id: "employees",
  },
  {
    title: "Registered User",
    iconStyle: imagepaths.AdminLeadsIcon,
    iconActive: imagepaths.AdminLeadsIconActive,
    to: "/app/registeredUsers",
    id: ["registeredusers", "userdetails"],
  },
  {
    title: "Leads",
    iconStyle: imagepaths.SingleUserIcon,
    iconActive: imagepaths.SingleUserIconActive,
    to: "/app/leads",
    id: "leads",
  },
  {
    title: "Applications",
    iconStyle: imagepaths.applicationCheckImg,
    iconActive: imagepaths.applicationCheckActiveImg,
    to: "/app/loanapplications",
    id: ["loanapplications", "loanapplicationsview"],
  },
  {
    title: "Loan Details",
    iconStyle: imagepaths.applicationCheckImg,
    iconActive: imagepaths.applicationCheckActiveImg,
    to: "/app/loandetails",
    id: ["loandetails", "loandetailspendingview", "loandetailsactiveview", "loandetailsclosedview", "manualdisbursementview"]
  },
  {
    title: "Master Data",
    iconStyle: imagepaths.MasterDataIcon,
    iconActive: imagepaths.MasterDataIconActive,
    to: "/app/masterdata",
    id: "masterdata",
  },
  {
    title: "Know Your Customer",
    iconStyle: imagepaths.PersonSearchIcon,
    iconActive: imagepaths.PersonSearchIconActive,
    to: "/app/kyc",
    id: ["kyc", "creditscore"],
  },
  {
    title: "Dedup Check",
    iconStyle: imagepaths.DupeCheckIcon,
    iconActive: imagepaths.DupeCheckIconActive,
    to: "/app/dupecheck",
    id: "dupecheck",
  },
  // {
  //   title: "Reports",
  //   iconStyle: imagepaths.reportsIcon,
  //   iconActive: imagepaths.reportsIconActive,
  //   to: "/app/equifaxreports",
  //   id: "equifaxreports",
  // },
  {
    title: "Reports",
    iconStyle: imagepaths.reportsIcon,
    iconActive: imagepaths.reportsIconActive,
    to: "/app/equifaxreports",
    id: ["equifaxreports"],
    classChange: "mm-collapse",
    content: [
      {
        title: "Equifax Report",
        iconStyle: imagepaths.reportsIcon,
        iconActive: imagepaths.reportsIconActive,
        to: "/app/equifaxreports",
        id: ["equifaxreports"],
      },
      {
        title: "Disbursement",
        iconStyle: imagepaths.reportsIcon,
        iconActive: imagepaths.reportsIconActive,
        to: "/app/disbursementreports",
        id: ["disbursementreports"],
      },
      {
        title: "Repayment",
        iconStyle: imagepaths.reportsIcon,
        iconActive: imagepaths.reportsIconActive,
        to: "/app/repaymentreports",
        id: ["repaymentreports"],
      },
      {
        title: "MIS",
        iconStyle: imagepaths.reportsIcon,
        iconActive: imagepaths.reportsIconActive,
        classChange: "mm-link",
        id: "mis",
      },
    ],
  },

  // {
  //   title: "Leads",
  //   iconStyle: imagepaths.AdminLeadsIcon,
  //   iconActive: imagepaths.AdminLeadsIconActive,
  //   to: "/app/businesstobusiness",
  //   id: ["businesstobusiness", "businesstocustomer", "digitalleads", "offlineleads"],
  //   classChange: "mm-collapse",
  //   content: [
  //     {
  //       title: "B2B",
  //       iconStyle: imagepaths.SidebarDot,
  //       iconActive: imagepaths.SidebarDot,
  //       to: "/app/businesstobusiness",
  //       // id: "businesstobusiness",
  //       id: ["businesstobusiness", "digitalleads", "offlineleads"],
  //     },
  //     {
  //       title: "B2C",
  //       iconStyle: imagepaths.SidebarDot,
  //       iconActive: imagepaths.SidebarDot,
  //       to: "/app/businesstocustomer",
  //       id: "businesstocustomer",
  //     },
  //   ],
  // }, 
];
